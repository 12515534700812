import PropTypes from "prop-types";

import { Link, useSearchParams } from "react-router-dom";

import { formatUpdateDate } from "../../utils/helpers";

export default function PortfolioLink({ portfolio }) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project");
  const { id, name, pdfs, updatedAt } = portfolio;

  return (
    <Link
      to={`/projects?project=${projectId}&module=5&portfolio=${id}`}
      className="rounded-2xl hover:shadow-inner dark:hover:shadow-secondary-dark hover:shadow-secondary-light bg-secondary-light dark:bg-secondary-dark border-light dark:border-dark dark:shadow-secondary-dark shadow-secondary-light group flex flex-col justify-between p-6 space-y-4 duration-200 border shadow transition-all">
      <h2 className="text-link-light dark:text-link-dark group-hover:text-link-hover dark:group-hover:text-link-hoverDark group-hover:underline text-2xl font-bold duration-200 transition-all">
        {name}
      </h2>
      <p className="!text-ellipsis text-start text-secondary-light dark:text-secondary-dark">
        <span className="text-tertiary-light dark:text-tertiary-dark font-semibold">
          PDF's:
        </span>{" "}
        {pdfs}
      </p>
      <div className="text-secondary-light dark:text-secondary-dark flex flex-col items-start space-y-1 text-sm">
        <p>
          <strong className="text-tertiary-light dark:text-tertiary-dark font-semibold">
            Last updated:
          </strong>{" "}
          {formatUpdateDate(updatedAt)}
        </p>
      </div>
    </Link>
  );
}

PortfolioLink.propTypes = {
  portfolio: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    pdfs: PropTypes.number.isRequired,
    updatedAt: PropTypes.string,
  }).isRequired,
};
