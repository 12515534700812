import { useEffect, useRef } from "react";

import PropTypes from "prop-types";

import useLockBodyScroll from "../../hooks/useLockBodyScroll";

export default function DeletionModal({ onConfirm, close, isLoading }) {
  useLockBodyScroll();

  const modalRef = useRef(null);
  const firstButtonRef = useRef(null);
  const lastButtonRef = useRef(null);

  // Focus trap
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Tab") {
        const focusableElements = modalRef.current.querySelectorAll("button");
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (e.shiftKey) {
          // Shift + Tab
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          // Tab
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      } else if (e.key === "Escape" && !isLoading) {
        // Close modal on Escape key
        close();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Set focus on the first button when the modal opens
    firstButtonRef.current.focus();

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [close, isLoading]);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      role="dialog"
      aria-modal="true"
      ref={modalRef}>
      <div className="bg-primary-light dark:bg-primary-dark p-6 rounded-lg shadow-lg border border-light dark:border-dark">
        <h2 className="text-primary-light dark:text-primary-dark text-xl font-bold mb-4">
          Confirm Deletion
        </h2>
        <p className="text-secondary-light dark:text-secondary-dark mb-6">
          Are you sure you want to delete this PDF?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            disabled={isLoading}
            ref={firstButtonRef}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded shadow transition-all duration-200"
            onClick={onConfirm}>
            {isLoading ? "Deleting..." : "Delete"}
          </button>
          <button
            disabled={isLoading}
            ref={lastButtonRef}
            className="bg-secondary-btn-light hover:bg-secondary-btn-hover text-primary-light font-bold py-2 px-4 rounded shadow transition-all"
            onClick={close}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

DeletionModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
