import PropTypes from "prop-types";

import { useState } from "react";

import HeaderMenuButton from "../../ui/HeaderMenuButton";
import modules from "../../../data/modules.json";

import ModuleLink from "./ModuleLink";

export default function ModuleSelectorButton({ projectId, moduleId }) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  return (
    <div className="w-1/2 md:w-auto relative">
      <HeaderMenuButton setIsOpen={setIsOpenMenu} image="module">
        Modules
      </HeaderMenuButton>
      <menu
        className={`top-14 rounded-xl w-[200%] sm:w-[125%] md:w-[300%] p-6 absolute right-0 bg-tertiary-light dark:bg-tertiary-dark shadow-lg shadow-light dark:shadow-dark ${
          isOpenMenu ? "visible opacity-100" : "opacity-0 invisible"
        } transition-all duration-300`}>
        <h3 className="text-2xl font-semibold text-center mb-4 text-secondary-light dark:text-secondary-dark">
          Modules
        </h3>
        <ul className="flex flex-col space-y-4">
          {modules.map((module) => (
            <ModuleLink
              moduleId={moduleId}
              setIsOpen={setIsOpenMenu}
              projectId={projectId}
              module={module}
              key={module.id}
              open={isOpenMenu}
            />
          ))}
        </ul>
      </menu>
    </div>
  );
}

ModuleSelectorButton.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  moduleId: PropTypes.number,
};
