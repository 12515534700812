import { useState, useEffect, useContext } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

import axios from "axios";

import { GlobalContext } from "../contexts/GlobalContext";
import { API_URL } from "../utils/api";

export default function usePortfolio() {
  const { state, dispatch } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("project");
  const portfolioId = searchParams.get("portfolio");

  const [pdfDocs, setPdfDocs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate("/projects");
      return;
    } else if (!portfolioId) {
      navigate(`/projects?project=${id}`);
      return;
    }
    const getPortfolio = async () => {
      toast.dismiss();
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await axios.get(
          `${API_URL}/pdf_docs/get_pdfs_from_portfolio?portfolio_id=${portfolioId}`
        );
        if (response.status !== 200) throw response;
        else if (
          response.data?.error === "Portfolio does not belong to user."
        ) {
          response.data.detail = "This portfolio does not exist.";
          throw response;
        } else {
          const pdfDocs = response.data.map((pdf) => ({
            id: pdf.id,
            name: pdf.name,
            notes: pdf.notes,
            reading_priority: Number(pdf.reading_priority),
            updatedAt: pdf.updatedAt,
            createdAt: pdf.createdAt,
          }));
          setPdfDocs(pdfDocs);
        }
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while retrieving portfolio, please try again."
            );
        console.log("Error getting portfolio: ", error);
        navigate(`/projects?project=${id}&module=5`);
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };
    getPortfolio();
  }, [navigate, id, dispatch, portfolioId]);

  return {
    loadingInformation: state.loadingInformation,
    id: portfolioId,
    pdfDocs,
    setPdfDocs,
  };
}
