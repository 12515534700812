import PropTypes, { oneOfType } from "prop-types";

export default function InputField({
  id = "",
  type = "text",
  value = "",
  setValue = () => {},
  error = "" | false,
  resetError = () => {},
  required = false,
  disabled = false,
  readOnly = false,
  autocomplete = "off",
}) {
  // split id by capital letters and join with space
  const label = id.split(/(?=[A-Z])/).join(" ");
  const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);

  return (
    <>
      <label
        className="text-secondary-light dark:text-secondary-dark font-semibold"
        htmlFor={id}>
        {`${required ? "* " : ""}${capitalizedLabel}`}
      </label>
      <input
        autoComplete={autocomplete}
        type={type}
        id={id}
        name={id}
        disabled={disabled}
        readOnly={readOnly}
        className={`${
          error ? "border-red-500" : "border-light dark:border-dark"
        } w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark`}
        placeholder={`${capitalizedLabel} goes here...`}
        onChange={({ target }) => {
          setValue(target.value);
          resetError();
        }}
        value={value}
      />
    </>
  );
}

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  error: oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  resetError: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  autocomplete: PropTypes.string,
};
