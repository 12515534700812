import PropTypes from "prop-types";

import { useState } from "react";

import { handlePdfDeletion } from "../../utils/pdf_docs/helpers";

import DeletionModal from "./DeletionModal";

export default function PdfCard({ pdf, setPdfDocs }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="rounded-lg bg-secondary-light dark:bg-secondary-dark border-light dark:border-dark dark:shadow-secondary-dark shadow-secondary-light duration-200 border shadow transition-all overflow-clip relative">
      {isModalOpen && (
        <DeletionModal
          onConfirm={() => handlePdfDeletion(pdf.id, setPdfDocs, setIsLoading)}
          close={() => setIsModalOpen(false)}
          isLoading={isLoading}
        />
      )}

      <button
        className="absolute top-0 right-0 bg-red-600 hover:bg-red-700 text-white font-bold p-2 rounded-bl-lg duration-200"
        onClick={() => setIsModalOpen(true)}>
        <img
          src="https://storage.googleapis.com/gpp4-bucket/src/trash.svg"
          alt="Delete pdf"
          className="w-6 h-6"
        />
      </button>
      <div className="absolute top-0 left-0 bg-secondary-light dark:bg-secondary-dark text-white font-bold p-2 rounded-br-lg">
        PDF
      </div>
      <img
        className="h-48 w-full object-cover object-top "
        src={`https://storage.googleapis.com/gpp-pdf/img/${pdf.id}.jpeg`}
        alt={`Preview of ${pdf.name}`}
      />
      <div className="p-4 space-y-4">
        <div className="space-y-2">
          <div className="font-bold text-xl text-primary-light dark:text-primary-dark">
            {pdf.name}
          </div>
          <p className="text-secondary-light dark:text-secondary-dark text-base">
            {pdf.notes}
          </p>
          <p className="text-tertiary-light dark:text-tertiary-dark text-sm">
            Priority: {pdf.reading_priority}
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-tertiary-light dark:text-tertiary-dark text-sm">
            Created: {new Date(pdf.createdAt).toLocaleDateString()}
          </p>

          <p className="text-tertiary-light dark:text-tertiary-dark text-sm">
            Updated:{" "}
            {pdf.updatedAt
              ? new Date(pdf.updatedAt).toLocaleDateString()
              : "Never updated"}
          </p>
          <a
            href={`https://storage.googleapis.com/gpp-pdf/pdf/${pdf.id}.pdf`}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center space-x-2 px-4 py-2 bg-primary-btn-light hover:bg-primary-btn-hover text-white font-bold rounded dark:bg-primary-btn-dark dark:hover:bg-primary-btn-hoverDark duration-200">
            <span>View PDF</span>{" "}
            <img
              src="https://storage.googleapis.com/gpp4-bucket/src/open-new-window.svg"
              alt="Open pdf in new window"
              className="w-5 h-5 inline"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

PdfCard.propTypes = {
  pdf: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    reading_priority: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
  }).isRequired,
  setPdfDocs: PropTypes.func.isRequired,
};
