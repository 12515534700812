import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";

// Function to calculate Aliphatic Index and counts of A, V, I, and L
function calculateAliphaticIndex(sequence) {
  const aliphaticResidues = ["A", "V", "I", "L"];
  let residueCounts = { A: 0, V: 0, I: 0, L: 0 };

  for (let aa of sequence.toUpperCase()) {
    if (aliphaticResidues.includes(aa)) {
      residueCounts[aa] += 1;
    }
  }

  const totalAliphaticCount = Object.values(residueCounts).reduce(
    (a, b) => a + b,
    0
  );
  const aliphaticIndex = sequence.length
    ? (totalAliphaticCount / sequence.length).toFixed(4)
    : "0.0000";

  return { aliphaticIndex, residueCounts };
}

export default function AliphaticIndexCalculator() {
  const [sequence, setSequence] = useState("");
  const [result, setResult] = useState({
    aliphaticIndex: "0.0000",
    residueCounts: { A: 0, V: 0, I: 0, L: 0 },
  });

  const handleSequenceChange = (e) => {
    const inputSequence = e.target.value
      .toUpperCase()
      .replace(/[^ACDEFGHIKLMNPQRSTVWY]/g, "");
    setSequence(inputSequence);

    if (inputSequence) {
      setResult(calculateAliphaticIndex(inputSequence));
    } else {
      setResult({
        aliphaticIndex: "0.0000",
        residueCounts: { A: 0, V: 0, I: 0, L: 0 },
      });
    }
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Aliphatic Index Calculator">
        <ToolsSelector id={10} />
      </PageHeader>
      <ContentLayout>
        {/* Input Sequence Section */}
        <section className="space-y-2">
          <label
            htmlFor="sequence"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Enter Your Protein Sequence
          </label>
          <textarea
            id="sequence"
            value={sequence}
            onChange={handleSequenceChange}
            cols="30"
            rows="10"
            placeholder=">Sequence"
            className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border-light dark:border-dark dark:focus:border-blue-400 focus:border-blue-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md w-full p-2 placeholder-gray-500 duration-200 border rounded-lg outline-none"
          />
        </section>

        {/* Results Section */}
        <section className="space-y-4">
          <h3 className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            Results
          </h3>
          <div className="space-y-2">
            <label
              htmlFor="aliphaticIndex"
              className="text-secondary-light dark:text-secondary-dark text-xl font-medium">
              Aliphatic Index
            </label>
            <input
              type="text"
              id="aliphaticIndex"
              value={result.aliphaticIndex}
              readOnly
              className="dark:bg-gray-800 text-primary-light dark:text-primary-dark border-light dark:border-dark w-full p-2 bg-white border rounded-lg outline-none"
            />
          </div>

          {/* Residue Counts Section */}
          <div>
            <h4 className="text-secondary-light dark:text-secondary-dark text-xl font-medium">
              Residue Counts
            </h4>
            <div className="grid grid-cols-4 gap-4">
              {Object.entries(result.residueCounts).map(([residue, count]) => (
                <input
                  key={residue}
                  value={`${residue}: ${count}`}
                  readOnly
                  className="dark:bg-gray-800 text-primary-light dark:text-primary-dark border-light dark:border-dark w-full p-2 text-center bg-white border rounded-lg outline-none"
                />
              ))}
            </div>
          </div>
        </section>
      </ContentLayout>
    </MainLayout>
  );
}
