import PropTypes from "prop-types";

export default function DeleteProject({
  closeEditNotes,
  disabled = false,
  openModal,
}) {
  return (
    <>
      <div className="flex justify-end">
        <button
          disabled={disabled}
          className="enabled:hover:bg-red-600 dark:bg-red-600 dark:enabled:hover:bg-red-700 rounded-xl disabled:cursor-not-allowed disabled:bg-opacity-70 hover:bg-red-600 sm:w-fit w-full px-4 py-2 text-lg font-semibold text-white duration-200 bg-red-500"
          onClick={() => {
            closeEditNotes();
            openModal();
          }}>
          Delete project
        </button>
      </div>
    </>
  );
}

DeleteProject.propTypes = {
  id: PropTypes.number.isRequired,
  closeEditNotes: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
};
