import { useState } from "react";

import { useNavigate } from "react-router-dom";

import PageHeader from "../ui/PageHeader";
import Spacer from "../ui/Spacer";
import useRetrieveProjectInfo from "../../hooks/useRetrieveProjectInfo";
import ContentLayout from "../../layouts/ContentLayout";
import { handleDeleteProject } from "../../utils/project-info/helpers";

import DeletionModal from "./DeletionModal";
import ProjectNotesDisplay from "./ProjectNotesDisplay";
import ProjectNotesForm from "./ProjectNotesForm";

import CreatedAt from "./ui/CreatedAt";
import DeleteProject from "./ui/DeleteProject";
import ModuleSelectorButton from "./ui/ModuleSelectorButton";
import Name from "./ui/Name";
import UpdatedAt from "./ui/UpdatedAt";

export default function ProjectDetails() {
  const { projectDetails, loadingInformation, notes, setNotes } =
    useRetrieveProjectInfo();
  const { project_id: id, name, createdAt, updatedAt } = projectDetails;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [canEditNotes, setCanEditNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {!loadingInformation && (
        <PageHeader to={`/projects`} title="Project Details">
          <ModuleSelectorButton projectId={id} />
        </PageHeader>
      )}
      {isOpenModal && (
        <DeletionModal
          close={() => {
            setIsOpenModal(false);
            setCanEditNotes(false);
          }}
          isLoading={isLoading}
          onConfirm={() => {
            handleDeleteProject(id, navigate, setIsLoading);
          }}
        />
      )}
      <ContentLayout>
        <Name name={name || ""} />
        <CreatedAt date={createdAt || ""} />
        <UpdatedAt date={updatedAt || ""} />
        {canEditNotes ? (
          <ProjectNotesForm
            id={id}
            originalNotes={notes}
            setCanEditNotes={setCanEditNotes}
            setProjectNotes={setNotes}
          />
        ) : (
          <ProjectNotesDisplay
            notes={notes}
            setCanEditNotes={setCanEditNotes}
          />
        )}
        <Spacer />
        <DeleteProject
          id={id}
          disabled={loadingInformation}
          closeEditNotes={() => setCanEditNotes(false)}
          openModal={() => setIsOpenModal(true)}
        />
      </ContentLayout>
    </>
  );
}
