import axios from "axios";

import toast from "react-hot-toast";

import { API_URL } from "../api";

export async function updateNotes(id, notes, setIsLoading, setProjectNotes) {
  setIsLoading(true);

  const trimmedNotes = notes.trim(); // Remove leading and trailing whitespaces

  try {
    const response = await axios.post(
      `${API_URL}/users/update_notes?project_id=${id}&notes=${trimmedNotes}`
    );
    if (response.status !== 200) throw response;

    setProjectNotes(trimmedNotes); // in case of successful API call, update project notes to avoid fetching again

    return { ok: true, message: "Notes updated successfully!" }; // return success message and ok status
  } catch (error) {
    // return error message and ok status
    return {
      ok: false,
      message: error?.data?.detail
        ? error.data.detail
        : "An error occurred while updating notes.",
    };
  } finally {
    setIsLoading(false); // set loading to false after API call
  }
}

export async function handleDeleteProject(id, navigate, setIsLoading) {
  toast.dismiss();
  setIsLoading(true);
  try {
    const response = await axios.post(
      `${API_URL}/users/delete_project?project_id=${id}`
    );
    if (response.status !== 200) throw response;
    navigate("/projects");
  } catch (error) {
    error?.data?.detail
      ? toast.error(`Error: ${error.data.detail}`)
      : toast.error(
          "An error occurred while processing your request, please try again."
        );
  } finally {
    setIsLoading(false);
  }
}
