import { useState } from "react";

import Portfolio from "../module5/Portfolio";
import PortfolioSelector from "../module5/PortfolioSelector";
import PageHeader from "../ui/PageHeader";
import useValidateProject from "../../hooks/useValidateProject";
import ModuleSelectorButton from "../project_details/ui/ModuleSelectorButton";

export default function Module5() {
  const {
    loadingInformation,
    id: projectId,
    portfolioId,
  } = useValidateProject();
  const [isOpenAddNewPortfolio, setIsOpenAddNewPortfolio] = useState(false);

  return (
    <>
      {!loadingInformation && (
        <PageHeader
          to={
            portfolioId
              ? `/projects?project=${projectId}&module=5`
              : `/projects?project=${projectId}`
          }
          title="Module 5">
          <ModuleSelectorButton projectId={projectId} moduleId={5} />
        </PageHeader>
      )}
      {!portfolioId && (
        <PortfolioSelector
          isOpenAddNewPortfolio={isOpenAddNewPortfolio}
          setIsOpenAddNewPortfolio={setIsOpenAddNewPortfolio}
        />
      )}
      {portfolioId && (
        <Portfolio
          isFormOpen={isOpenAddNewPortfolio}
          handleClick={setIsOpenAddNewPortfolio}
        />
      )}
    </>
  );
}
