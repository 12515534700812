import toast from "react-hot-toast";

import axios from "axios";

import { API_URL } from "../api";

export function handlePdfDeletion(id, setPdfDocs, setIsLoading) {
  setIsLoading(true);
  toast.dismiss();
  axios
    .delete(`${API_URL}/pdf_docs/delete_pdf?pdf_id=${id}`)
    .then((response) => {
      if (response.status !== 200) throw response;
      toast.success("PDF deleted successfully!");
      setPdfDocs((prevDocs) => prevDocs.filter((doc) => doc.id !== id));
    })
    .catch((error) => {
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error(
            "An error occurred while deleting the PDF, please try again."
          );
      console.log("Error deleting PDF: ", error);
    })
    .finally(() => {
      setIsLoading(false);
    });
}
