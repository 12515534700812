import useInitializeStage from "../../hooks/useInitializeStage";
import useLoadFile from "../../hooks/useLoadFile";

export default function MoleculeViewer() {
  const stageRef = useInitializeStage();
  const setFile = useLoadFile(stageRef);

  return (
    <div className="flex flex-col space-y-4">
      <label
        className="font-semibold text-xl text-primary-light dark:text-primary-dark w-fit cursor-pointer"
        htmlFor="file">
        Upload .pdb or .pdbqt file
      </label>
      <input
        id="file"
        name="file"
        type="file"
        accept=".pdb,.pdbqt"
        onChange={({ target }) => {
          if (target.files[0]) setFile(target.files[0]);
        }}
        className="text-sm text-secondary-light dark:text-secondary-dark file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:text-primary-dark file:bg-primary-btn-light file:dark:bg-primary-btn-dark file:hover:bg-primary-btn-hover file:dark:hover:bg-primary-btn-hoverDark  hover:cursor-pointer w-fit file:cursor-pointer duration-200 transition-all"
      />
      <div
        id="viewport"
        className="w-full h-96 border border-light dark:border-dark rounded-md shadow-inner shadow-light dark:shadow-dark"
      />
    </div>
  );
}
