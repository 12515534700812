import PageTitle from "../ui/PageTitle";
import useRetrievePortfolios from "../../hooks/useRetrievePortfolios";
import ContentLayout from "../../layouts/ContentLayout";

import CreateNewPortfolioButton from "./CreateNewPortfolioButton";
import CreateNewPortfolioForm from "./CreateNewPortfolioForm";
import Portfolios from "./Portfolios";

export default function PortfolioSelector({
  isOpenAddNewPortfolio,
  setIsOpenAddNewPortfolio,
}) {
  const { portfolios, setPortfolios, loadingInformation } =
    useRetrievePortfolios();

  return (
    !loadingInformation && (
      <ContentLayout>
        <PageTitle>Portfolios</PageTitle>
        {portfolios?.length === 0 && (
          <>
            <h3 className="text-secondary-light dark:text-secondary-dark text-2xl font-bold text-center">
              You don't have any portfolios yet
            </h3>
            <article className="bg-secondary-light dark:bg-secondary-dark dark:border-dark border-light lg:w-1/2 relative flex flex-col items-center justify-center w-full p-4 mx-auto space-y-6 text-center border rounded-lg shadow-md">
              {isOpenAddNewPortfolio ? (
                <CreateNewPortfolioForm
                  closeForm={() => setIsOpenAddNewPortfolio(false)}
                  setPortfolios={setPortfolios}
                />
              ) : (
                <CreateNewPortfolioButton
                  openForm={() => setIsOpenAddNewPortfolio(true)}
                />
              )}
            </article>
          </>
        )}
        {portfolios?.length > 0 && (
          <Portfolios
            setPortfolios={setPortfolios}
            portfolios={portfolios}
            handleClick={setIsOpenAddNewPortfolio}
            isFormOpen={isOpenAddNewPortfolio}
          />
        )}
      </ContentLayout>
    )
  );
}
