import PropTypes from "prop-types";

import { useContext } from "react";

import { GlobalContext } from "../../../contexts/GlobalContext";
import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { getImageUrl } from "../../../utils/helpers";

export default function LoadingScreen({ content = "" }) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  useLockBodyScroll();

  return (
    <div className="bg-secondary-light bg-clip-padding backdrop-filter backdrop-blur-sm dark:bg-secondary-dark absolute top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full space-y-2 bg-opacity-50">
      <img
        src={getImageUrl("loader.svg", isDark)}
        alt="loader"
        className="w-16 h-16"
      />
      {content && content.trim().length > 0 && (
        <p className="text-lg font-semibold text-white">{content}</p>
      )}
    </div>
  );
}

LoadingScreen.propTypes = {
  content: PropTypes.string,
};
