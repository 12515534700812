import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";

// Function to calculate aromaticity score and residue counts
function calculateAromaticity(sequence) {
  if (!sequence || sequence.length === 0) {
    return {
      aromaticityScore: "Invalid sequence",
      residueCounts: {
        F: 0,
        Y: 0,
        W: 0,
        H: 0,
      },
    };
  }

  const aromaticResidues = ["F", "Y", "W", "H"];
  let residueCounts = { F: 0, Y: 0, W: 0, H: 0 };

  for (let aa of sequence.toUpperCase()) {
    if (aromaticResidues.includes(aa)) {
      residueCounts[aa] += 1;
    }
  }

  const totalAromaticCount = Object.values(residueCounts).reduce(
    (a, b) => a + b,
    0
  );
  const aromaticity = totalAromaticCount / sequence.length;

  return {
    aromaticityScore: aromaticity.toFixed(4),
    residueCounts: residueCounts,
  };
}

const getOriginalResultState = () => ({
  aromaticityScore: "",
  residueCounts: { F: 0, Y: 0, W: 0, H: 0 },
});

export default function AromaticityScore() {
  const [sequence, setSequence] = useState("");
  const [result, setResult] = useState(getOriginalResultState);

  const handleCalculation = () => {
    const calcResult = calculateAromaticity(sequence);
    setResult(calcResult);
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Aromaticity Score Tool">
        <ToolsSelector id={11} />
      </PageHeader>
      <ContentLayout>
        {/* Input Sequence Section */}
        <section className="space-y-2">
          <label
            htmlFor="sequence"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your Protein Sequence
          </label>
          <textarea
            value={sequence}
            onChange={(e) => {
              const input = e.target.value.toUpperCase();
              const validInput = input.replace(/[^A-Z]/g, ""); // Only allow valid characters
              setResult(getOriginalResultState());
              setSequence(validInput);
            }}
            name="sequence"
            id="sequence"
            cols="30"
            rows="10"
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            placeholder=">Insert sequence here"
          />
        </section>

        {/* Result Section */}
        <section className="flex flex-col space-y-2">
          <button
            onClick={handleCalculation}
            disabled={result.aromaticityScore !== ""}
            className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark text-primary-dark md:w-fit disabled:cursor-not-allowed disabled:bg-opacity-50 w-full px-4 py-2 pr-6 mb-4 text-lg font-semibold text-center duration-200 rounded-lg">
            Calculate Aromaticity
          </button>

          <label
            htmlFor="aromaticity"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            Aromaticity Score
          </label>
          <input
            readOnly
            disabled
            name="aromaticity"
            id="aromaticity"
            value={result.aromaticityScore || ""}
            className="dark:bg-gray-800 text-primary-light dark:text-primary-dark border-light dark:border-dark w-full p-2 bg-white border rounded-lg outline-none"
            placeholder="Aromaticity Score"
          />
        </section>

        {/* Residue Counts Section */}
        <section className="space-y-2">
          <h3 className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            Residue Counts
          </h3>
          <div className="grid grid-cols-4 gap-4">
            {Object.keys(result.residueCounts).map((residue) => (
              <input
                key={residue}
                readOnly
                disabled
                name={residue}
                value={`${residue}: ${result.residueCounts[residue]}`}
                className="dark:bg-gray-800 text-primary-light dark:text-primary-dark border-light dark:border-dark w-full p-2 text-center bg-white border rounded-lg outline-none"
              />
            ))}
          </div>
        </section>
      </ContentLayout>
    </MainLayout>
  );
}
