import { useState } from "react";

import PageTitle from "../ui/PageTitle";
import usePortfolio from "../../hooks/usePortfolio";
import ContentLayout from "../../layouts/ContentLayout";

import PdfCard from "./PdfCard";
import UploadPdfButton from "./UploadPdfButton";
import UploadPdfForm from "./UploadPdfForm";

export default function Portfolio({ isFormOpen, handleClick }) {
  const [isOpenAddNewPDF, setIsOpenAddNewPDF] = useState(false);
  const { loadingInformation, pdfDocs, setPdfDocs } = usePortfolio();
  return (
    !loadingInformation && (
      <ContentLayout>
        <PageTitle>Portfolio</PageTitle>
        {pdfDocs?.length === 0 && (
          <>
            <h3 className="text-secondary-light dark:text-secondary-dark text-2xl font-bold text-center">
              You haven't uploaded any PDFs yet
            </h3>
            <article className="bg-secondary-light dark:bg-secondary-dark dark:border-dark border-light lg:w-1/2 relative flex flex-col items-center justify-center p-4 mx-auto space-y-6 text-center border rounded-lg shadow-md w-full">
              {isOpenAddNewPDF ? (
                <UploadPdfForm
                  closeForm={() => setIsOpenAddNewPDF(false)}
                  setPdfDocs={setPdfDocs}
                />
              ) : (
                <UploadPdfButton openForm={() => setIsOpenAddNewPDF(true)} />
              )}
            </article>
          </>
        )}
        {pdfDocs.length > 0 && (
          <section className="sm:grid-cols-2 lg:grid-cols-3 grid grid-cols-1 gap-4">
            {isFormOpen ? (
              <article className="bg-secondary-light dark:bg-secondary-dark dark:border-dark border-light relative flex flex-col items-center justify-center p-4 space-y-4 text-center border rounded-lg shadow-md">
                <UploadPdfForm
                  closeForm={() => handleClick(false)}
                  setPdfDocs={setPdfDocs}
                />
              </article>
            ) : (
              <button
                onClick={() => handleClick(true)}
                className="rounded-2xl hover:shadow-xl bg-primary-btn-light dark:bg-primary-btn-dark hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark shadow-light dark:shadow-dark flex flex-col items-center justify-center p-10 space-y-4 text-xl text-center duration-300 shadow-md cursor-pointer">
                <p className="text-[7rem] text-primary-dark">+</p>
                <p className="text-primary-dark text-lg">Upload New PDF</p>
              </button>
            )}
            {pdfDocs.map((pdf) => (
              <PdfCard key={pdf.id} pdf={pdf} setPdfDocs={setPdfDocs} />
            ))}
          </section>
        )}
      </ContentLayout>
    )
  );
}
