import PropTypes from "prop-types";

import CreateNewPortfolioForm from "./CreateNewPortfolioForm";
import PortfolioLink from "./PortfolioLink";

export default function Portfolios({
  portfolios,
  setPortfolios,
  handleClick,
  isFormOpen = false,
}) {
  return (
    <section className="sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 grid grid-cols-1 gap-4">
      {isFormOpen ? (
        <article className="bg-secondary-light dark:bg-secondary-dark dark:border-dark border-light relative flex flex-col items-center justify-center p-4 space-y-4 text-center border rounded-lg shadow-md">
          <CreateNewPortfolioForm
            closeForm={() => handleClick(false)}
            setPortfolios={setPortfolios}
          />
        </article>
      ) : (
        <button
          onClick={() => handleClick(true)}
          className="rounded-2xl hover:shadow-xl bg-primary-btn-light dark:bg-primary-btn-dark hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark shadow-light dark:shadow-dark flex flex-col items-center justify-center p-10 space-y-4 text-xl text-center duration-300 shadow-md cursor-pointer">
          <p className="text-[7rem] text-primary-dark">+</p>
          <p className="text-primary-dark text-lg">Create New Portfolio</p>
        </button>
      )}
      {portfolios.map((portfolio) => (
        <PortfolioLink key={portfolio.id} portfolio={portfolio} />
      ))}
    </section>
  );
}

Portfolios.propTypes = {
  portfolios: PropTypes.array.isRequired,
  setPortfolios: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  isFormOpen: PropTypes.bool,
};
