import PropTypes from "prop-types";

import { useContext, useState } from "react";

import axios from "axios";

import toast from "react-hot-toast";

import { useSearchParams } from "react-router-dom";

import { GlobalContext } from "../../contexts/GlobalContext";
import { API_URL } from "../../utils/api";
import { getImageUrl } from "../../utils/helpers";

export default function UploadPdfForm({ closeForm, setPdfDocs }) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;
  const [searchParams] = useSearchParams();
  const portfolioId = searchParams.get("portfolio");

  const [description, setDescription] = useState("");
  const [readingPriority, setReadingPriority] = useState("");
  const [pdfFile, setPdfFile] = useState(null);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function validateData() {
    const newErrors = {};
    if (!description.trim())
      newErrors.description = "PDF description are required";
    if (!readingPriority.trim())
      newErrors.readingPriority = "Reading priority is required";
    if (!pdfFile) newErrors.pdfFile = "PDF file is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!validateData()) return;

    setIsLoading(true);
    toast.dismiss();

    const formData = new FormData();
    formData.append(
      "pdf_data",
      JSON.stringify({
        name: pdfFile.name.split(".pdf")[0],
        size: (pdfFile.size / (1024 * 1024)).toFixed(2),
        reading_priority: readingPriority,
        notes: description,
      })
    );
    formData.append("pdfFile", pdfFile);

    try {
      const response = await axios.post(
        `${API_URL}/pdf_docs/upload_pdf/v2?portfolio_id=${portfolioId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) throw response;

      const pdfDocs = response.data.map((pdf) => ({
        id: pdf.id,
        name: pdf.name,
        notes: pdf.notes,
        reading_priority: Number(pdf.reading_priority),
        updatedAt: pdf.updatedAt,
        createdAt: pdf.createdAt,
      }));
      setPdfDocs(pdfDocs);
      toast.success("PDF uploaded successfully!");
      closeForm();
    } catch (error) {
      toast.error(
        error?.response?.data?.detail || "An error occurred. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <button
        className="top-4 right-4 absolute lg:top-2 lg:right-2"
        onClick={closeForm}>
        <img
          src={getImageUrl("close.svg", isDark)}
          alt="close"
          className="hover:bg-tertiary-light dark:hover:bg-tertiary-dark w-8 h-8 p-1 duration-200 rounded-full cursor-pointer"
        />
      </button>
      <h2 className="text-secondary-light dark:text-secondary-dark text-2xl font-bold">
        Upload New PDF
      </h2>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center w-full space-y-8">
        {/* Description */}
        <div className="flex flex-col items-start w-full space-y-2">
          <label
            htmlFor="pdfDescription"
            className="text-tertiary-light dark:text-tertiary-dark font-semibold">
            * PDF Description
          </label>
          <textarea
            disabled={isLoading}
            id="pdfDescription"
            placeholder="(max: 100 characters)"
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark ${
              errors.description
                ? "!border-red-500"
                : "!border-light dark:!border-dark"
            }`}
            value={description}
            onChange={(e) => {
              e.target.value.length > 100
                ? setErrors({
                    ...errors,
                    description: "Character limit reached",
                  })
                : setErrors({ ...errors, description: "" });
              setDescription((prev) =>
                e.target.value.length > 100 ? prev : e.target.value
              );
            }}
          />
          {errors.description && (
            <p className="text-red-500">{errors.description}</p>
          )}
        </div>

        {/* Reading Priority */}
        <div className="flex flex-col items-start w-full space-y-2">
          <label
            htmlFor="readingPriority"
            className="text-tertiary-light dark:text-tertiary-dark font-semibold">
            * Reading Priority
          </label>
          <input
            id="readingPriority"
            disabled={isLoading}
            type="text"
            placeholder="e.g., High, Medium, Low"
            className={`w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:!bg-gray-200 dark:disabled:!bg-gray-700 ${
              errors.readingPriority
                ? "border-red-500"
                : "!border-light dark:!border-dark"
            }`}
            value={readingPriority}
            onChange={(e) => setReadingPriority(e.target.value)}
          />
          {errors.readingPriority && (
            <p className="text-red-500">{errors.readingPriority}</p>
          )}
        </div>

        {/* PDF File */}
        <div className="flex flex-col items-start w-full space-y-2">
          <label
            htmlFor="pdfFile"
            className="font-semibold text-xl text-primary-light dark:text-primary-dark w-fit cursor-pointer">
            * PDF File
          </label>
          <input
            disabled={isLoading}
            id="pdfFile"
            type="file"
            accept="application/pdf"
            className="text-sm text-secondary-light dark:text-secondary-dark file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:text-primary-dark file:bg-primary-btn-light file:dark:bg-primary-btn-dark file:hover:bg-primary-btn-hover file:dark:hover:bg-primary-btn-hoverDark  hover:cursor-pointer w-fit file:cursor-pointer duration-200 transition-all"
            onChange={(e) => setPdfFile(e.target.files[0])}
          />
          {errors.pdfFile && <p className="text-red-500">{errors.pdfFile}</p>}
        </div>

        <div className="w-full space-y-4">
          <button
            className="enabled:hover:bg-blue-600 dark:bg-blue-600 dark:enabled:hover:bg-blue-700 rounded-xl disabled:cursor-not-allowed disabled:bg-opacity-70 hover:bg-blue-600 w-full px-4 py-2 text-lg font-semibold text-white duration-200 bg-blue-500"
            disabled={isLoading}>
            {isLoading ? (
              <img
                src="https://storage.googleapis.com/gpp4-bucket/src/light-loader.svg"
                alt="loader"
                className="w-7 h-7 mx-auto"
              />
            ) : (
              "Upload PDF"
            )}
          </button>
          <p className="dark:text-gray-300 text-xs text-center">
            * Required field
          </p>
        </div>
      </form>
    </>
  );
}

UploadPdfForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  setPdfDocs: PropTypes.func.isRequired,
};
